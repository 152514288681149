<template>
  <v-card elevation="0">
    <!-- <v-card-title class="display-2 mb-4">{{ $t('events.upcoming-challenges') }}</v-card-title> -->
    <h1 class="display-2 mb-4">{{ $t('events.upcoming-challenges') }}</h1>

    <div v-if="profile && profile.events && profile.events.length > 0">
      <v-card-title class="subtitle mb-0 pb-0">{{ $t('profile.overview.my-events') }}</v-card-title>
      <EventGrid :items="profile.events" class="mx-4 mb-4 mt-4"></EventGrid>
      <v-card-title class="subtitle mt-6 mb-0 pb-0">{{ $t('events.public-upcoming-events') }}</v-card-title>
    </div>      

    <v-alert v-if="tenant.id !== 'wmm'" prominent tile type="info">
      {{ $t('events.intro-disclaimer') }}
    </v-alert>

    <EventGrid :items="events" class="mx-4 mb-4 mt-4"></EventGrid>
    <br/>



    <v-divider/>
    <v-card-title v-if="tenant.showFeatures">Your event on {{tenant.name}}?</v-card-title>
    <v-card-text v-if="tenant.showFeatures">
      <p>Want to feature your (virtual) event on {{tenant.name}}? We support a wide range of features to build unique experiences.</p>
      <ul class="mb-4">
        <li>Badges and virtual course maps</li>
        <li>Individual and team rankings</li>
        <li>Many engaging challenge modes: best result, total distance, total time, streak challenges, etc.</li>
        <li><router-link to="/pricing">Simple pricing</router-link></li>
      </ul>
      <v-btn color="accent" class="mr-4" :to="{ name: 'eventmanagerCreate'}">Create event</v-btn>
      <v-btn outlined color="accent" class="mr-4" :to="{name: 'features' }">All Features</v-btn>
      <v-btn outlined color="accent" :to="{name: 'contact' }">Contact us</v-btn>
    </v-card-text>
  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      profile: null,
    };
  },
  async mounted() {
    var response = await eventService.upcoming();
    this.events = response.data.data;

    await this.getProfile();

    //this.$vuetify.theme.custom = true;
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });

  },
  methods: {
    
    async getProfile() {
      if (this.user) {
        var response = await profileService.get(/*full:*/true);
        this.profile = response.data;
        console.log('Profile loaded');
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

